const getters = {
    getNotificacoes: state => {
        return state.notificacoes;
    },

    getConfiguracaoNotificacoes: state => {
        return state.configuracaoNotificacoes;
    }
};
export default getters
