import axios from "axios";
import store from '../store';

export function getAssistenciasTecnicas(
    offset, 
    limit, 
    idPessoa = null,
    {
        protocolo = null,
        idempreendimento = null,
        idbloco = null,
        idunidade = null,
        iditem = null,
        cadastro = null,
        idsituacao = null,
    }) {
    return axios.get("/assistencias", {
        headers: {
            Authorization: `Bearer ${store.state.login.access_token}`,
        },
        params: {
            offset: offset,
            limit: limit,
            idPessoa: idPessoa,
            idassistencia: encodeURIComponent(protocolo || ''),
            idempreendimento: idempreendimento,
            idbloco: idbloco,
            idunidade: idunidade,
            iditem: iditem,
            data_cad: cadastro,
            idsituacao: idsituacao,
        },
    });
}

export function getAssistenciaTecnica(idAssistencia) {
    return axios.get('/assistencia/' + idAssistencia, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function retornarLocalidades() {
    return axios.get(`/assistencia/localidade`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function retornarItensManutencao(idItem = null) {
    return axios.get(`/assistencia/itens-manutencao`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
        params: {
            iditem: idItem
        }
    });
}

export function retornarAreaComum(idEmpreendimento = null) {
    return axios.get(`/assistencia/area-comum`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
        params: {
            idEmpreendimento: idEmpreendimento
        }
    });
}

export function cadastrarAssistencia(dados) {
    return axios.post('/assistencia', dados, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
    });
}

export function cadastrarMensagem(idAssistencia, data) {
    return axios.post(`/assistencia/${idAssistencia}/mensagem`, data, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function uploadArquivos(dados) {
    return axios.post('/assistencia/upload-arquivos', dados, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`,
            'Content-Type': 'multipart/form-data'
        },
    });
}

export function retornarDatasDisponiveis(idItem, idAssistencia) {
    return axios.get(`/assistencia/` + idAssistencia + `/item-datas-disponiveis/` + idItem, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function retornarHorarioDisponivel(idEquipe, idAssistencia, data) {
    return axios.get(`/assistencia/` + idAssistencia + `/equipe/` + idEquipe + `/horarios-disponiveis/` + data, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}

export function cadastrarVisita(idAssistencia, dados) {
    return axios.post(`/assistencia/` + idAssistencia + `/cadastrar-visita`, dados, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
    });
}

export function retornarSituacoes() {
    return axios.get(`/assistencia/situacoes`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
    });
}

export function retornarCamposAdicionais() {
    return axios.get(`/campos-adicionais/assistencias`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        }
    });
}