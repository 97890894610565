<template>
  <div class="pc-content">
    <BreadCrumbs :caminho="caminho"/>
    <TituloPagina titulo="Central de Notificações" subtitulo="Configuração das notificações que deseja receber"/>
    <div class="vld-parent">
      <Aviso
        :exibir="alerta.exibir"
        :mensagem="alerta.mensagem"
        :tipo="alerta.tipo"
      />
      <div class="pc-bloco">
        <LoadingComponent :isLoading="isLoading"/>
        <div class="row">
          <div class="col-12">
            <FormularioNotificacoesSecao
              titulo="Atendimento"
              :campos="camposAtendimento"
              :configuracaoNotificacoes="configuracaoNotificacoesLocal"
              @update="atualizaCampo"
            />
            <FormularioNotificacoesSecao
              titulo="Assistência"
              :campos="camposAssistencia"
              :configuracaoNotificacoes="configuracaoNotificacoesLocal"
              @update="atualizaCampo"
            />
            <FormularioNotificacoesSecao
              titulo="Agendamento"
              :campos="camposAgendamento"
              :configuracaoNotificacoes="configuracaoNotificacoesLocal"
              @update="atualizaCampo"
            />
            <FormularioNotificacoesSecao
              titulo="Empreendimento"
              :campos="camposEmpreendimento"
              :configuracaoNotificacoes="configuracaoNotificacoesLocal"
              @update="atualizaCampo"
            />
            <FormularioNotificacoesSecao
              titulo="Mural"
              :campos="camposMural"
              :configuracaoNotificacoes="configuracaoNotificacoesLocal"
              @update="atualizaCampo"
            />
            <FormularioNotificacoesSecao
              titulo="Pesquisa"
              :campos="camposPesquisa"
              :configuracaoNotificacoes="configuracaoNotificacoesLocal"
              @update="atualizaCampo"
            />
          </div>
        </div>
        <br/>
        <div class="row">
          <div class="col-12 btnEnviar">
            <button
              :disabled="acessoComo"
              name="btSend"
              type="button"
              @click="salvarDados"
              value="Alterar"
              class="btn-form pc-btnBox pc-btnBox-secondary"
            >
              Alterar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs";
import Aviso from "@/components/Aviso";
import TituloPagina from "@/components/TituloPagina";
import {objetoTraking} from "@/plugins/Utils";
import Vue from "vue";
import FormularioNotificacoesSecao from "@/pages/configuracoes/components/FormularioNotificacoesSecao";
import {mapGetters} from "vuex";
import LoadingComponent from "@/components/LoadingComponent";
import {postConfiguracaoNotificacoes} from "@/services/Notificacoes";

export default {
  components: {
    LoadingComponent,
    FormularioNotificacoesSecao,
    TituloPagina,
    BreadCrumbs,
    Aviso,
  },

  computed: {
    ...mapGetters({
      configuracaoNotificacoes: "getConfiguracaoNotificacoes",
      acessoComo: "getAcessoComo",
    })
  },

  created() {
    this.trakingMixpanel("Central de Notificações", "Formulário", true, false);

    this.carregarConfiguracaoNotificacoes();
  },

  data() {
    return {
      isLoading: false,
      alerta: {
        exibir: false,
        mensagem: "",
        tipo: "sucesso",
      },
      caminho: [
        {
          texto: "Central de Notificações",
          ativo: true,
          rota: null,
        },
      ],
      configuracaoNotificacoesOriginal: [],
      configuracaoNotificacoesLocal: [],
      camposAtendimento: [
        {id: 'atendimento_atualizacao_situacao_cliente', label: 'Alteração de situação do atendimento'},
        {id: 'atendimento_nova_mensagem_cliente', label: 'Nova mensagem no atendimento'},
      ],
      camposAssistencia: [
        {id: 'assistencia_atualizacao_situacao_cliente', label: 'Alteração de situação da assistência'},
        {id: 'assistencia_nova_visita_cliente', label: 'Nova visita técnica na assistência'},
      ],
      camposAgendamento: [
        {id: 'agendamento_alteracao_situacao_cliente', label: 'Alteração de situação do agendamento'},
        {id: 'agendamento_novo_contrato_cliente', label: 'Novo contrato de agendamento'},
      ],
      camposPesquisa: [
        {id: 'pesquisa_nova_pesquisa_cliente', label: 'Nova pesquisa'},
      ],
      camposEmpreendimento: [
        {id: 'empreendimento_atualizacao_obra_cliente', label: 'Atualização da obra'},
        {id: 'empreendimento_novo_documento_cliente', label: 'Novo documento no empreendimento'},
        {id: 'empreendimento_novo_documento_unidade_cliente', label: 'Novo documento na unidade'},
      ],
      camposMural: [
        {id: 'muraladm_novo_mural', label: 'Novo mural'},
      ],
    };
  },

  methods: {
    async carregarConfiguracaoNotificacoes() {
      await this.$store.dispatch('getConfiguracaoNotificacoes')
        .then(() => {
          this.configuracaoNotificacoesLocal = this.$store.getters.getConfiguracaoNotificacoes;
          this.gerarConfiguracaoNotificacoesLocal(
            this.camposAtendimento,
            this.camposAssistencia,
            this.camposAgendamento,
            this.camposEmpreendimento,
            this.camposMural,
            this.camposPesquisa
          );
        });
    },

    gerarConfiguracaoNotificacoesLocal(...arrayCampos) {
      this.configuracaoNotificacoes.forEach(config => {
        delete config.idnotificacao;
        delete config.idusuario;
        delete config.painel;
      });
      this.configuracaoNotificacoesOriginal = JSON.parse(JSON.stringify(this.configuracaoNotificacoes));
      this.configuracaoNotificacoesLocal = JSON.parse(JSON.stringify(this.configuracaoNotificacoes));
      arrayCampos.forEach(campos => {
        campos.forEach(campo => {
          if (!this.configuracaoNotificacoesLocal.some(config => config.notificacao === campo.id)) {
            this.configuracaoNotificacoesLocal.push({
              notificacao: campo.id,
              sms: true,
              email: true,
              desktop: true,
              mobile: true
            });
          }
        });
      });
    },

    atualizaCampo({id, campo}) {
      let itemDesejado = this.configuracaoNotificacoesLocal.find((configuracao) => {
        return configuracao.notificacao === id;
      });
      itemDesejado[campo] = !itemDesejado[campo];
    },

    obterConfiguracoesModificadas() {
      return this.configuracaoNotificacoesLocal.filter(local => {
        const original = this.configuracaoNotificacoesOriginal.find(config => config.notificacao === local.notificacao);
        return JSON.stringify(local) !== JSON.stringify(original);
      });
    },

    salvarDados() {
      if (this.acessoComo) {
        return;
      }

      this.isLoading = true;
      this.trakingMixpanel("Alterar configuração de notificação", "Alterar", true, true);
      let erros = [];
      this.obterConfiguracoesModificadas().map(configuracaoModificada => {
        postConfiguracaoNotificacoes(configuracaoModificada)
          .catch((error) => {
            erros.push(error);
          });
      });
      setTimeout(() => {
        if (erros.length !== 0) {
          this.avisoErro(erros[0]);
        } else {
          this.alerta = {
            exibir: true,
            mensagem: "Seus dados foram atualizados com sucesso!",
            tipo: "sucesso",
          };
        }
        this.carregarConfiguracaoNotificacoes();
        this.isLoading = false;
      }, 1000);
    },

    avisoErro(error) {
      let mensagem = "Erro interno, por favor tentar mais tarde.";
      if (error.response.status !== 422) {
        mensagem = error.response.data.message;
      }
      this.alerta = {
        exibir: true,
        tipo: "erro",
        mensagem: mensagem,
      };
    },

    trakingMixpanel(acao, subacao, intencao, ateracao) {
      this.$mixpanel.track(
        Vue.prototype.$eventoMixPanel,
        objetoTraking("Central de Notificações", acao, subacao, intencao, ateracao)
      );
    },
  },
};
</script>
