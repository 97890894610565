const state = {
    concluido: {
        pessoa: false,
        sindico: false,
        unidades: false,
        termos: false,
        atualizacaoCadastral: false,
        permissoes: false,
        permissoesSindico: false,
        configuracaoNotificacoes: false,
    },
    sessaoExpirada: false,
    primeiroAcessoHome: true,
    rotaRedirecionar: null,
    mensagemLogin: null,
};

export default state;
