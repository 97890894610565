<template>
  <div class="mb-5">
    <div class="pc-titleBloco">
      <div>
        <h3>{{ titulo }}</h3>
      </div>
    </div>
    <div class="pc-tabela">
      <div class="pc-tabela--row pc-tabela--head">
        <div class="pc-tabela--td pc-f2">
          <p>Notificação</p>
        </div>
        <div class="pc-tabela--td pc-f1">
          <p>Push (Navegador)</p>
        </div>
        <div class="pc-tabela--td pc-f1">
          <p>Push (Aplicativo)</p>
        </div>
      </div>
      <CampoNotificacoes
        v-for="campo in campos"
        :id="campo.id"
        :label="campo.label"
        :push-desktop="configuracaoNotificacoes.find((configuracao) => {
              return configuracao.notificacao === campo.id;
            })?.desktop ?? true"
        :push-mobile="configuracaoNotificacoes.find((configuracao) => {
              return configuracao.notificacao === campo.id;
            })?.mobile ?? true"
        @update="atualizaCampo"
      />
    </div>
  </div>
</template>
<script>
import CampoNotificacoes from "@/pages/configuracoes/components/CampoNotificacoes";

export default {
  props: {
    titulo: {type: String, required: true},
    campos: {type: Array, required: true},
    configuracaoNotificacoes: {type: Array, required: true},
  },

  components: {
    CampoNotificacoes,
  },

  methods: {
    atualizaCampo({id, campo}) {
      this.$emit("update", {id, campo});
    },
  },
};
</script>
