<template>
    <div class="iframe">
        <LoadingComponent :isLoading="isLoading" />
        <iframe class="p-t-0" name="myIframe" title="plugin" @load="isLoading = false"></iframe>
        <form ref="form" method="post" :action="url" target="myIframe">
            <input type="hidden" name="token" :value="token">
            <input type="hidden" name="slug" :value="slug">
            <input type="hidden" name="idconfiguracao" :value="idconfiguracao">
        </form>
    </div>
</template>

<script>
import LoadingComponent from "@/components/LoadingComponent";
import {objetoTraking} from "../../plugins/Utils";
import Vue from "vue";

export default {
    props: {
        url: { type: String },
        token: { type: String },
        slug: { type: String },
        idconfiguracao: { type: String|Number },
    },

    created() {
        this.$mixpanel.track(
            Vue.prototype.$eventoMixPanel,
            objetoTraking('Plugin', 'Plugin', 'Exibir', true, false)
        );
    },

    components: {
        LoadingComponent,
    },

    data() {
        return {
            isLoading: true,
        };
    },
    
    mounted() {
        this.$refs.form.submit();
    },
};
</script>

<style>
iframe {
    display: block;
    width: 100%;
    height: 90vh;
    border: none;
    overflow: auto;
    padding-top: 20px;
}
</style>
