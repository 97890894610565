<template>
	<div>
		<!-- <BannerAfiliados /> -->
		<PesquisaBar v-if="pesquisabar" />
		<div class="body-wrapper" :class="{ down: pesquisabar }">
			<PaginaTopo ref="paginaTopo" />
			<div class="pc-overlayMenuXs"></div>
			<div class="pc-wrapper container-fluid">
				<PaginaMenu />
				<div class="pc-rightColumn">
					<ModalSemUnidades />
					<router-view></router-view>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PaginaTopo from "./_partials/PaginaTopo.vue";
import PaginaMenu from "./_partials/PaginaMenu.vue";
import ModalSemUnidades from "./_partials/ModalSemUnidades.vue";
import PesquisaBar from "../pages/home/components/PesquisaBar.vue";
import { getToken, messaging, onMessage } from "@/configs/firebase";
import { cadastrarDispositivo } from "@/services/Notificacoes";
import store from "@/store";
import BannerAfiliados from "../pages/home/components/BannerAfiliados.vue";

function sendTokenToServer(currentToken) {
	if (!isTokenSentToServer(currentToken)) {
		let data = {
			identificador_dispositivo: currentToken,
			origem: "WEB",
		};
		cadastrarDispositivo(data);
		setTokenNotification(currentToken);
	}
}

function isTokenSentToServer(currentToken) {
	return window.localStorage.getItem("sentToServer") === currentToken;
}

function setTokenNotification(currentToken) {
	window.localStorage.setItem("sentToServer", currentToken);
}

export default {
	props: ["handlePesquisaBarProps"],
	components: {
		PaginaTopo,
		PaginaMenu,
		ModalSemUnidades,
		PesquisaBar,
		BannerAfiliados,
	},
	data() {
		return {
			pesquisabar: false,
		};
	},
	mounted() {
		if (!store.state.login.acesso_sindico) {
			onMessage(messaging, (payload) => {
				console.log("Message received. ", payload);
				this.$refs.paginaTopo.notificacaoAtiva = true;
				this.$vToastify.success(payload.data.body, payload.data.title);
			});

			getToken(messaging, {
				vapidKey:
					"BMayox1YAIOE8A5YuuSXDOJWsDjczEHvYGLEaej1IeyiHzp_PkslSX4vbXc5-eTscvzsrPi1hep-F6aOtDZ9gtY",
			})
				.then((currentToken) => {
					if (currentToken) {
						console.log("FCM Token:", currentToken);
						sendTokenToServer(currentToken);
					} else {
						console.log(
							"No registration token available. Request permission to generate one."
						);
						setTokenNotification("");
					}
				})
				.catch((err) => {
					console.log(
						"An error occurred while retrieving token. ",
						err
					);
					setTokenNotification("");
				});
		}

		this.$root.$on("pesquisaStatus", () => {
			this.pesquisabar = !this.pesquisabar;
		});
	},
};
</script>
