// src/configs/firebase.js
import { initializeApp } from 'firebase/app';
import { isSupported, getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyDDCUbkeZ8SeiSUg3h1ZmyIXWphK9YxQBA",
    authDomain: "cv-crm-push-notifications.firebaseapp.com",
    projectId: "cv-crm-push-notifications",
    storageBucket: "cv-crm-push-notifications.appspot.com",
    messagingSenderId: "728452254390",
    appId: "1:728452254390:web:c64b9198c59ba75d80f417"
};

const app = initializeApp(firebaseConfig);

let messaging = null;

isSupported().then((supported) => {
    if (supported) {
        messaging = getMessaging(app);
    }
});

export { messaging, getToken, onMessage };