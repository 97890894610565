<template>
    <div class="sidebar-notifications-overlay" :class="show" @click.self="hideSidebarNotifications">
        <div class="sidebar-notifications" :class="show">
            <div class="sidebar-notifications-header">
                <h3>Central de Notificações</h3>
                <button class="sidebar-button-close" @click="hideSidebarNotifications">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
                        <path fill="#000000" d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path>
                    </svg>
                </button>
            </div>
            <div class="sidebar-notifications-body">
                <div class="notifications-section">
                    <div class="notifications-header">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 16" fill="none">
                            <path d="M17.25 1C16.8344 1 16.5 1.33437 16.5 1.75V1.92813L1.5 6.01875V5.75C1.5 5.33437 1.16562 5 0.75 5C0.334375 5 0 5.33437 0 5.75V6.42812V7V9V9.57187V10.25C0 10.6656 0.334375 11 0.75 11C1.16562 11 1.5 10.6656 1.5 10.25V9.98125L5.175 10.9844C5.0625 11.3031 5 11.6438 5 12C5 13.6562 6.34375 15 8 15C9.46562 15 10.6875 13.95 10.9469 12.5594L16.5 14.0719V14.25C16.5 14.6656 16.8344 15 17.25 15C17.6656 15 18 14.6656 18 14.25V13.5V2.5V1.75C18 1.33437 17.6656 1 17.25 1ZM16.5 12.5188L1.5 8.42813V7.57188L16.5 3.48125V12.5188ZM6.5 12C6.5 11.7781 6.54688 11.5688 6.63438 11.3813L9.49375 12.1594C9.4125 12.9125 8.775 13.4969 8.00313 13.4969C7.175 13.4969 6.50313 12.825 6.50313 11.9969L6.5 12Z" fill="#333333"/>
                        </svg>
                        <h4>Comunicados</h4>
                    </div>
                    <div v-for="notificacao in notificacoes.notificacoes" v-bind:key="notificacao.idmensagem" :class="['notifications-card', {'--viewed': notificacao.lido}]" @click="lerNotificacao(notificacao.idmensagem, notificacao.url)">
                        <svg class="status-icon" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                            <circle cx="4" cy="4.5" r="4" fill="#D9222B"/>
                        </svg>
                        <div class="notifications-card-content">
                            <span class="subject">{{notificacao.titulo}}</span>
                            <span class="details">{{notificacao.corpo}}</span>
                        </div>
                        <svg class="arrow-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                            <path d="M15.7063 9.20625C16.0969 8.81563 16.0969 8.18125 15.7063 7.79063L11.7063 3.79063C11.3156 3.4 10.6812 3.4 10.2906 3.79063C9.9 4.18125 9.9 4.81563 10.2906 5.20625L12.5844 7.5H1C0.446875 7.5 0 7.94688 0 8.5C0 9.05313 0.446875 9.5 1 9.5H12.5844L10.2906 11.7938C9.9 12.1844 9.9 12.8188 10.2906 13.2094C10.6812 13.6 11.3156 13.6 11.7063 13.2094L15.7063 9.20938V9.20625Z" fill="#333333"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {marcarNotificacaoComoLida} from "@/services/Notificacoes";
import {mapState} from "vuex";

    export default {
        data() {
            return {
                show: "",
            };
        },

        computed: {
            ...mapState({
                notificacoes: state => state.notificacoes
            }),
        },

        methods: {
            async showSidebarNotifications() {
                document.querySelector('#icon-notification').classList.remove('active');
                document.body.classList.add('sidebar-notifications-open');
                await this.$store.dispatch('getNotificacoes');
                this.show = "show";
            },
            hideSidebarNotifications() {
                document.body.classList.remove('sidebar-notifications-open');
                this.show = "";
            },
            async lerNotificacao(idNotificacao, url) {
                await marcarNotificacaoComoLida(idNotificacao).then(() => {
                    if (url) {
                        window.location.href = url;
                    }
                });
            },
        },

        mounted() {
            document.addEventListener('keydown', (e) => {
                if (this.show && e.key === 'Escape') {
                    this.hideSidebarNotifications();
                }
            });
        }
    };
</script>

<style scoped>
    .sidebar-notifications-overlay {
        position: fixed;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 99999;
        visibility: hidden;
        transition: all 0.35s ease-in-out;
    }

    .sidebar-notifications-overlay.show {
        visibility: visible;
    }

    .sidebar-notifications {
        position: fixed;
        top: 0;
        right: -300px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 300px;
        height: 100%;
        background: #fff;
        border-radius: 4px 0 0 0;
        transition: all 0.35s ease-in-out;
    }

    .sidebar-notifications.show {
        right: 0;
    }

    .sidebar-notifications-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
    }

    .sidebar-notifications-header h3 {
        font-size: 14px;
        font-weight: 700;
        color: #2f2f2f;
        margin-bottom: 0;
    }

    .sidebar-button-close {
        padding: 0;
        background: none;
        border: none;
        cursor: pointer;
    }

    .sidebar-notifications-body {
        flex: 1;
        padding: 20px;
        border-top: 2px solid #d9d9d9;
        background-color: #f6f7f8;
        overflow-y: auto;
    }

    .notifications-section {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 40px;
    }

    .notifications-section:first-child {
        margin-top: 0;
    }

    .notifications-header {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .notifications-header h4 {
        font-family: "Circular Std", sans-serif !important;
        font-size: 14px;
        font-weight: 700;
        line-height: normal;
        color: #2f2f2f;
        text-transform: none;
    }

    .notifications-card {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        gap: 16px;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);
        cursor: pointer;
    }

    .notifications-card:hover {
        background: #e7e7e7;
        transition: all 0.35s ease-in-out;
    }

    .notifications-card.--viewed .status-icon {
        display: none;
    }

    .notifications-card-content {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .notifications-card-content .subject {
        font-size: 16px;
        font-weight: 700;
        line-height: normal;
        color: #010101;
    }
    
    .notifications-card-content .details {
        font-size: 14px;
        font-weight: 700;
        line-height: normal;
        color: #848484;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .notifications-card .arrow-icon {
        margin-left: auto;
    }

    @media screen and (max-width: 768px) {
        .sidebar-notifications {
            width: 100%;
            right: -100%;
        }
    }
</style>
