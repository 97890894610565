import { getDefaultState } from "./state";
import {listarNotificacoes, obterConfiguracaoNotificacoes} from "@/services/Notificacoes";

export default {
    async getNotificacoes({ commit }) {
        await listarNotificacoes()
            .then(response => {
                commit('SET_NOTIFICACOES', response.data.data);
                const hasUnread = response.data.data.some(notificacao => !notificacao.lido);
                commit('SET_NOTIFICACAO_PENDENTE', hasUnread);
            }).finally(() => {
                commit('CONCLUIDO_NOTIFICACOES');
            });
    },

    async getConfiguracaoNotificacoes({ commit }) {
        await obterConfiguracaoNotificacoes()
            .then(response => {
                commit('SET_CONFIGURACAO_NOTIFICACOES', response.data.data);
            }).finally(() => {
                commit('CONCLUIDO_CONFIGURACAO_NOTIFICACOES');
            });
    },

    resetState({ state }) {
        Object.assign(state, getDefaultState());
    },
}
