const getDefaultState = () => {
    return {
        notificacoes: null,
        notificacao_pendente: false,
        configuracaoNotificacoes: [],
    }
};

const state = getDefaultState();

export {getDefaultState}
export default state;
