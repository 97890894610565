import axios from "axios";
import store from "@/store";

export function cadastrarDispositivo(dados) {
    return axios.post('/cadastrar-dispositivo', dados, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
    });
}

export function listarNotificacoes() {
    return axios.get(`/notificacoes`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
    });
}

export function marcarNotificacaoComoLida(idMensagem) {
    return axios.put(`/notificacoes/${idMensagem}/lida`, null, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
    });
}

export function obterConfiguracaoNotificacoes() {
    return axios.get(`/configuracao-notificacoes`, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
    });
}

export function postConfiguracaoNotificacoes(configuracaoNotificacoes) {
    return axios.post(`/configuracao-notificacoes`, configuracaoNotificacoes, {
        headers: {
            'Authorization': `Bearer ${store.state.login.access_token}`
        },
    });
}