<template>
    <div>
        <div v-if="tipoCampo === 'data'" class="form-group">
            <label :for="`form-${nome_de_referencia}`">
                <strong v-if="obrigatorio">*{{ label }}</strong>
                <span v-else>{{ label }}</span>
            </label>
            <input
                :id="`form-${nome_de_referencia}`"
                type="date"
                class="form-control"
                :placeholder="placeholder"
                :value="value"
                :required="obrigatorio"
                @input="$emit('input', $event.target.value)"
            />
        </div>

        <div v-else-if="tipoCampo === 'data_hora'" class="form-group">
            <label :for="`form-${nome_de_referencia}`">
                <strong v-if="obrigatorio">*{{ label }}</strong>
                <span v-else>{{ label }}</span>
            </label>
            <input
                :id="`form-${nome_de_referencia}`"
                type="datetime-local"
                class="form-control"
                :placeholder="placeholder"
                :required="obrigatorio"
                @input="$emit('input', $event.target.value)"
            />
        </div>

        <div v-else-if="tipoCampo === 'select'" class="form-group">
            <label :for="`form-${nome_de_referencia}`">
                <strong v-if="obrigatorio">*{{ label }}</strong>
                <span v-else>{{ label }}</span>
            </label>
            <select
                :id="`form-${nome_de_referencia}`"
                class="form-control"
                :required="obrigatorio"
                @input="updateValue($event.target.value)"
            >
                <option value="">Selecione uma opção</option>
                <option v-for="opcao in opcoes" :key="opcao" :value="opcao">
                    {{ opcao }}
                </option>
            </select>
        </div>

        <div v-else-if="tipoCampo === 'checkbox'" class="form-group">
            <label>
                <strong v-if="obrigatorio">*{{ label }}</strong>
                <span v-else>{{ label }}</span>
            </label>
            <div v-for="opcao in opcoes" :key="opcao" class="form-check">
                <input
                    type="checkbox"
                    :id="`form-${nome_de_referencia}-${opcao}`"
                    class="form-check-input"
                    :value="opcao"
                    :checked="value.includes(opcao)"
                    @change="toggleCheckbox(opcao)"
                />
                <label :for="`form-${nome_de_referencia}-${opcao}`" class="form-check-label">
                    {{ opcao }}
                </label>
            </div>
        </div>

        <div v-else-if="tipoCampo === 'texto_editor'" class="form-group">
            <label :for="`form-${nome_de_referencia}`">
                <strong v-if="obrigatorio">*{{ label }}</strong>
                <span v-else>{{ label }}</span>
            </label>
            <VueEditor
                :id="`form-${nome_de_referencia}`"
                v-model="textAreaContent"
                :placeholder="placeholder"
                :editorToolbar="customToolbar"
                @input="updateValue(textAreaContent)"
            />
        </div>

        <div v-else-if="tipoCampo === 'moeda'" class="form-group">
            <label :for="`form-${nome_de_referencia}`">
                <strong v-if="obrigatorio">*{{ label }}</strong>
                <span v-else>{{ label }}</span>
            </label>
            <input
                :id="`form-${nome_de_referencia}`"
                class="form-control"
                :placeholder="placeholder"
                :value="value"
                :required="obrigatorio"
                @input="tratarCampoMoeda($event.target.value)"
            />
        </div>

        <div v-else-if="tipoCampo === 'numero'" class="form-group">
            <label :for="`form-${nome_de_referencia}`">
                <strong v-if="obrigatorio">*{{ label }}</strong>
                <span v-else>{{ label }}</span>
            </label>
            <input
                type="number"
                :id="`form-${nome_de_referencia}`"
                class="form-control"
                :placeholder="placeholder"
                :value="value"
                :required="obrigatorio"
                @input="tratarCampoNumero($event.target.value)"
            />
        </div>

        <div v-else class="form-group">
            <label :for="`form-${nome_de_referencia}`">
                <strong v-if="obrigatorio">*{{ label }}</strong>
                <span v-else>{{ label }}</span>
            </label>
            <input
                :id="`form-${nome_de_referencia}`"
                class="form-control"
                :placeholder="placeholder"
                :value="value"
                :required="obrigatorio"
                @input="updateValue($event.target.value)"
            />
        </div>
    </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
    props: {
        tipoCampo: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        nome_de_referencia: {
            type: String,
            required: true
        },
        obrigatorio: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String
        },
        opcoes: {
            type: Array,
            required: false
        },
        value: {
            type: [String, Array],
            default: ''
        }
    },
    components: {
        VueEditor
    },
    data() {
        return {
            textAreaContent: '',
            customToolbar: [
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ align: [] }],
                ["link", "image"]
            ]
        }
    },
    methods: {
        updateValue(novoValor) {
            this.$emit('input', novoValor);
        },
        toggleCheckbox(value) {
            let newSelection = [...this.value];
            if (newSelection.includes(value)) {
                newSelection = newSelection.filter(item => item !== value);
            } else {
                newSelection.push(value);
            }
            this.$emit("update:value", newSelection);
        },
        tratarCampoNumero(value) {
            this.updateValue(value.replace(/\D/g, ''));
        },
        tratarCampoMoeda(value) {
            const valorMoeda = value.replace(/\D/g, '');
            const valorFormatado = new Intl.NumberFormat('pt-BR', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(valorMoeda / 100);
            this.updateValue(valorFormatado);
        }
    }
}
</script>