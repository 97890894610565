<template>
  <div class="pc-tabela--row">
    <div class="pc-tabela--td pc-f2">
      <p :data-title="label">{{ label }}</p>
    </div>
    <div class="pc-tabela--td pc-f1">
      <input
        type="checkbox"
        name="desktop"
        :id="id + '-desktop'"
        class="cv-switch-toggle"
        :checked="pushDesktop"
        @change="emitUpdate"
      />
      <label class="cv-switch-label" :for="id + '-desktop'">
        <div class="cv-switch"></div>
      </label>
    </div>
    <div class="pc-tabela--td pc-f1">
      <input
        type="checkbox"
        name="mobile"
        :id="id + '-mobile'"
        class="cv-switch-toggle"
        :checked="pushMobile"
        @change="emitUpdate"
      />
      <label class="cv-switch-label" :for="id + '-mobile'">
        <div class="cv-switch"></div>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {type: String, required: true},
    label: {type: String, required: true},
    pushDesktop: {type: Boolean, default: false, required: true},
    pushMobile: {type: Boolean, default: false, required: true}
  },

  methods: {
    emitUpdate(event) {
      this.$emit('update', {
        id: this.id,
        campo: event.target.name
      });
    },
  }
};
</script>

<style scoped>
.-cv-switch-wrap {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;

  .cv-switch-texto {
    white-space: nowrap;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: $cor-cinza-matiz-120;
  }
}
.cv-switch-label {
  display: inline-block;
  background: #bf302e;
  width: 50px;
  height: 22px;
  border-radius: 50px;
  position: relative;
  margin: 0;
  cursor: pointer;

  &:before {
    content: "Não";
    position: absolute;
    color: white;
    display: inline-block;
    font-size: 10px;
    top: 2px;
    right: 7px;
    line-height: 18px;
  }

  &:after {
    content: "";
    position: absolute;
    color: white;
    display: inline-block;
    font-size: 10px;
    top: 2px;
    left: 7px;
    line-height: 18px;
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
}
.cv-switch {
  position: absolute;
  width: 18px;
  height: 18px;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 50px;
  background: white;
  transition: transform 0.2s ease-in-out;
}
.cv-switch-toggle:checked ~ .cv-switch-label {
  background: #00b26f;

  &:before {
    content: "";
  }

  &:after {
    content: "Sim";
  }
}
.cv-switch-toggle:checked ~ .cv-switch-label .cv-switch {
  background: white;
  transform: translatex(28px);
  transition: transform 0.3s ease-in;
}
.cv-switch-toggle {
  display: none !important;
}
</style>