<template>
    <a href="javascript:void(0)" class="pc-btnSair">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 21 24"
            width="21"
            height="24"
            fill="none"
            @click="showSidebarNotifications"
        >
            <path
                fill="#fff"
                d="M10.5 0C9.6703 0 8.99999 0.670312 8.99999 1.5V2.33906C5.60155 2.87813 2.99999 5.82187 2.99999 9.375V10.9406C2.99999 13.0687 2.27343 15.1359 0.946864 16.7953L0.248427 17.6719C-0.0234481 18.0094 -0.0750106 18.4734 0.112489 18.8625C0.299989 19.2516 0.693739 19.5 1.12499 19.5H19.875C20.3062 19.5 20.7 19.2516 20.8875 18.8625C21.075 18.4734 21.0234 18.0094 20.7516 17.6719L20.0531 16.8C18.7266 15.1359 18 13.0687 18 10.9406V9.375C18 5.82187 15.3984 2.87813 12 2.33906V1.5C12 0.670312 11.3297 0 10.5 0ZM10.5 4.5H10.875C13.5656 4.5 15.75 6.68437 15.75 9.375V10.9406C15.75 13.1859 16.4016 15.375 17.6109 17.25H3.38905C4.59843 15.375 5.24999 13.1859 5.24999 10.9406V9.375C5.24999 6.68437 7.43436 4.5 10.125 4.5H10.5ZM13.5 21H10.5H7.49999C7.49999 21.7969 7.81405 22.5609 8.37655 23.1234C8.93905 23.6859 9.70311 24 10.5 24C11.2969 24 12.0609 23.6859 12.6234 23.1234C13.1859 22.5609 13.5 21.7969 13.5 21Z"
            />
        </svg>
    </a>
</template>

<style>
    .pc-menu-vertical .pc-topo .pc-top--content .pc-top--controles .pc-top--user .pc-btnSair svg path {
        @media (max-width: 991px) {
            fill: #fff;
        }
    }
</style>

<script>
    export default {
        methods: {
            showSidebarNotifications() {
                this.$emit('show-sidebar-notifications');
            }
        }
    };
</script>
