export default {
    SET_NOTIFICACOES(state, notificacoes) {
        state.notificacoes = notificacoes;
    },
    SET_NOTIFICACAO_PENDENTE(state, pendente) {
        state.notificacao_pendente = pendente;
    },
    SET_CONFIGURACAO_NOTIFICACOES(state, configuracaoNotificacoes) {
        state.configuracaoNotificacoes = configuracaoNotificacoes;
    },
}
