<template>
	<div v-if="showBanner" class="banner-afiliados">
		<div class="content">
			<button class="btn-close" @click="fechaModal">
				<IconClose />
			</button>
			<a href="javascript:void(0)" @click="abrirlink">
				<img src="@/assets/img/banner-afiliados.png" alt="" />
			</a>
		</div>
	</div>
</template>

<script>
import IconClose from "@/components/icons/IconClose";
import { objetoTraking } from "../../../plugins/Utils";
import Vue from "vue";

export default {
	components: {
		IconClose,
	},
	data() {
		return {
			showBanner: true,
		};
	},
	beforeMount() {
		this.trakingMixpanel("Banner Abriu", "Visualizou", false, true);
	},
	methods: {
		abrirlink() {
			this.trakingMixpanel("Banner Link", "Clicou", true, true);
			window.open("https://amzn.to/3CL6u2a", "_blank");
			this.showBanner = false;
		},
		fechaModal() {
			this.trakingMixpanel("Botão fechar", "Clicou", true, true);
			this.showBanner = false;
		},
		trakingMixpanel(acao, subacao, intencao, ateracao) {
			this.$mixpanel.track(
				Vue.prototype.$eventoMixPanel,
				objetoTraking(
					"Banner Afiliados",
					acao,
					subacao,
					intencao,
					ateracao
				)
			);
		},
	},
};
</script>

<style>
.banner-afiliados {
	position: fixed;
	z-index: 222222;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.7);

	img {
		max-width: calc(100% - 20px);
	}

	.content {
		position: relative;
	}

	.btn-close {
		position: absolute;
		top: 5px;
		right: 23px;
		border: none;
		cursor: pointer;
		color: white;
		background: none;

		svg {
			width: 15px;
			height: 15px;
			path {
				fill: white;
			}
		}
	}
}
</style>
