<template>
	<div class="pc-content mt-30">
		<ModalPesquisas v-if="sessao.primeiroAcessoHome" />
		<ModalPesquisasNPS />
		<ModalIndicarAmigo v-if="configuracoes.exibir_menu.indicar_amigo" />
		<ModalReservasPendentes
			v-if="sessao.primeiroAcessoHome"
			:primeiroAcesso="true"
		/>

		<div class="row">
			<div class="col-12">
				<div class="pc-titlePage">
					<div class="pc-titlePage--title" v-if="!acessoSindico">
						<h1>Portal do Cliente</h1>
						<p>Painel com informações da sua(s) unidade(s)</p>
					</div>
					<div class="pc-titlePage--title" v-else>
						<h1>Portal do Síndico</h1>
						<p>Painel com informações do seu condomínio</p>
					</div>
					<div class="pc-titlePage--unidade" v-if="!acessoSindico">
						<a
							v-if="configuracoes.exibir_menu.indicar_amigo"
							class="pc-btnGhost"
							data-toggle="modal"
							data-target="#indicaramigo"
							>Indicar um Amigo</a
						>
						<PropostasPendentes />
						<SelectUnidade />
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<UnidadePadrao />
			</div>
		</div>
		<div class="row" v-if="false">
			<div class="col-12">
				<Atalhos />
			</div>
		</div>
		<div class="row">
			<div v-bind:class="[acessoSindico ? 'col-12' : 'col-xl-8 col-12']">
				<div class="row">
					<div class="col-12">
						<BannerGeral />
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<Atalhos />
					</div>
				</div>
				<div class="row" v-if="false">
					<div class="col-12">
						<StatusObraNovo />
					</div>
				</div>
				<div
					class="row"
					v-if="false && configuracoes.exibir_menu.portal_beneficios"
				>
					<div class="col-12">
						<Oportunidades />
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<Comunicados />
					</div>
				</div>
			</div>

			<div class="col-xl-4 col-12" v-if="!acessoSindico">
				<BannerLateral />
				<StatusObra />
				<FotosObra />
			</div>
		</div>
	</div>
</template>

<style>
#silentbox-overlay {
	z-index: 999999 !important;
}
</style>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import StatusObra from "./components/StatusObra";
import StatusObraNovo from "./components/StatusObraNovo";
import FotosObra from "./components/FotosObra";
import Comunicados from "./components/Comunicados";
import Oportunidades from "./components/Oportunidades";
import BannerLateral from "./components/BannerLateral";
import BannerGeral from "./components/BannerGeral";
import UnidadePadrao from "@/components/UnidadePadrao";
import Atalhos from "./components/Atalhos";
import ModalIndicarAmigo from "./components/ModalIndicarAmigo";
import ModalReservasPendentes from "@/pages/home/components/ModalReservasPendentes";
import PropostasPendentes from "@/pages/home/components/PropostasPendentes";
import ModalPesquisas from "./components/ModalPesquisas.vue";
import PesquisaBtn from "./components/PesquisaBtn.vue";
import ModalPesquisasNPS from "./components/ModalPesquisasNPS.vue";
import SelectUnidade from "@/components/SelectUnidade.vue";

export default {
	computed: {
		...mapState({
			configuracoes: (state) => state.configuracoes.configuracoes,
			unidade: (state) => state.unidades.unidade,
			unidades: (state) => state.unidades.unidades,
			sessao: (state) => state.sessao,
			cliente: (state) => state.login.cliente,
		}),
		...mapGetters({
			acessoSindico: "getAcessoSindico",
		}),
	},
	methods: {
		...mapActions(["homeVisualizada"]),
	},

	name: "Home",

	components: {
		SelectUnidade,
		PropostasPendentes,
		BannerGeral,
		VueSlickCarousel,
		StatusObra,
		StatusObraNovo,
		FotosObra,
		Comunicados,
		Oportunidades,
		BannerLateral,
		UnidadePadrao,
		Atalhos,
		ModalReservasPendentes,
		ModalIndicarAmigo,
		ModalPesquisas,
		PesquisaBtn,
		ModalPesquisasNPS,
	},

	data() {
		return {
			settingsSlickGeral: {
				arrows: false,
				dots: false,
				infinite: true,
				autoplay: true,
				speed: 1000,
				autoplaySpeed: 3000,
				slidesToShow: 1,
				slidesToScroll: 1,
			},
			pesquisaNPS: true,
		};
	},

	beforeDestroy() {
		this.homeVisualizada();
	},

	mounted() {
		this.$root.$on("pesquisaStatusNPS", () => {
			this.pesquisaNPS = !this.pesquisaNPS;
		});
	},
};
</script>
